.topCard{
    z-index: 1001 !important;
    border-radius: 2%;

    position: absolute;
    width: 100%;
    padding: 0%;
    padding-bottom: 1%;
    margin: 0% auto; 

    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    
    display: table;
    table-layout: auto; /*Optional*/
    border-spacing: 10px; /*Optional*/
    
    .icon {
        display: table-cell;
        padding: 0px;
        margin: 0px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 7%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);// to center 
    }
    .name{
        display: table-cell;
        width: 60%;

        position: absolute;
        top: 50%;
        left: 45%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);// to center 
        text-align: center;

        margin: 0.5% 0px;
        padding: 0px;
        font-family: Montserrat , sans-serif;
        font-size: 1.5em;
        font-weight: 600;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .dropdown{ 
        width: 25%;
        display: table-cell;
        width: 75px;
        text-align: center;
    }
}
